<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
import router from "@/router/index.js";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Trajets annulés",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableData: [],
      title: "Trajets annulés",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Trajets annulés",
          active: true,
        },
      ],
      idtrajet:0,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100,250,500],
      filter: null,
      filterOn: [],
      sortBy: "dateHeureDepart",
      sortDesc: false,
      fields: [
        { key: "itinéraire", sortable: true },
        { key: "conducteur", sortable: true },
        { key: "Depart", sortable: true },
        { key: "prix", sortable: true },
        { key: "passagers", sortable: true },
        { key: "motif", sortable: true },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  async mounted() {
    //Chargement des données
    const data = await apiRequest(
      "GET",
      "admin/trajets/cancel",
      undefined,
      false
    );
    if (data && data.data) {
      console.log("data.data:", data.data);
      const formattedTable = data.data.map((trajet) => {
        var passagers = "";
        trajet.passagers.forEach((element) => {
          if (element.statut == "ACTIF") {
            passagers += "-"+element.firstname + " " + element.lastname+"\n";
          }
        });
        return {
          id:trajet.id,
          itinéraire:
            trajet.lieuDepart.nom.split(",")[0] +
            " >> " +
            trajet.lieuArrivee.nom.split(",")[0],
          conducteur:
            trajet.conducteur.personne.lastname + " " +
            trajet.conducteur.personne.firstname,
          Depart:  new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
          nombrePlace: trajet.nombrePlace,
          prix: trajet.prix + " XOF",
          motif: trajet.motifAnnulation,
          passagers: passagers/*trajet.passagers.map((passager) => {
            if (passager) {
              return passager.idClient;
            }
          })*/,
        };
      });
      this.tableData = formattedTable;
    }
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
     goToDetail(row) {
      console.log('row:', row)
      router.push(`/trajets/details_annules/${row.id}`)
      //router.push('/user/user_info/'+row.id)
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Trajets Annulés : {{ this.rows }}</h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Afficher&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;éléments
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Recherche:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Recherche..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                @row-clicked="goToDetail"
              ></b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
